import React from 'react';
import Modal from '@mui/material/Modal';
import { Typography, CardActions, Box } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const ConfirmationModal = ({
  open,
  handleClose,
  handleSuccess = () => null,
  loading
}) => {
  const onClose = () => {
    handleClose();
  };


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography variant="h3">
            Are you sure you want to create this order?
          </Typography>
          <Typography variant="h5">
            Ensure you have filled the correct values
          </Typography>
          <CardActions>
            <div style={{ marginTop: '10px' }}>
              <LoadingButton
                disabled={loading}
                loading={loading}
                onClick={handleSuccess}
                variant="outlined"
                color="success">
                Create
              </LoadingButton>
              <LoadingButton
                disabled={loading}
                loading={loading}
                onClick={onClose}
                style={{ marginLeft: '10px' }}
                variant="outlined">
                Close
              </LoadingButton>
            </div>
          </CardActions>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
