import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import toast, { Toaster } from 'react-hot-toast';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Autocomplete from '@mui/material/Autocomplete';
import orders from 'apis/orders';
import driversApi from 'apis/drivers';
import sendersApi from 'apis/senders';
import DeleteIcon from '@material-ui/icons/Delete';
import { LoadingButton } from '@mui/lab';
import ConfirmationModal from './ConfrimationModal';

// import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {},
  topSpace: {
    marginTop: '1rem'
  },
  textarea: {
    resize: 'both'
  },
  fullWidth: {
    width: '100%'
  }
}));

const CreateOrderDetails = props => {
  const { className, ...rest } = props;

  const [drivers, setDrivers] = React.useState([]);
  const [senders, setSenders] = React.useState([]);

  const fetchDriversAndSenders = async () => {
    const driversResponse = await driversApi.GetDriverNames();
    const sendersResponse = await sendersApi.GetSenderNames();

    setDrivers(
      driversResponse.data.drivers.map(driver => ({
        id: driver.id,
        label: driver.name,
        value: driver.id
      }))
    );
    setSenders(
      sendersResponse.data.senders.map(sender => ({
        id: sender.id,
        label: sender.name,
        value: sender.id
      }))
    );
  };

  React.useEffect(() => {
    fetchDriversAndSenders();
  }, []);

  const classes = useStyles();

  const defaultOrder = {
    sender: '',
    driver: '',
    status: 'completed',
    sender_address: '',
    receiver_address: '',
    receiver_name: '',
    receiver_phone: '',
    driver_fee: 0,
    amount: 0,
    driver_price_type: 'percentage',
    price: 0,
    images: [],
    items: [{ name: '', quantity: 0 }],
    sender_address_latitude: 0,
    sender_address_longitude: 0,
    receiver_address_latitude: 0,
    receiver_address_longitude: 0,
    distance: 0,
    minutes: 0
  }

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(defaultOrder);

  const handleChange = event => {
    const inputField = event.target.name;
    const inputValue = event.target.value;

    let update = { [inputField]: inputValue };

    if (inputField.includes('items-')) {
      const split = inputField.split('-');
      const index = split[1];
      const attr = split[2];

      const item = values.items[index];
      item[attr] = inputValue;

      values.items[index] = item;

      update.items = values.items;
    } else {
      update = { [inputField]: inputValue };
    }

    if (inputField === 'amount') {
      if (values.driver_price_type === 'percentage') {
        update.driver_fee = Math.round(Number((inputValue / 100) * values.price));
      } else {
        update.driver_fee = Math.round(Number(inputValue));
      }
    }

    if (inputField === 'driver_price_type') {
      if (inputValue === 'percentage') {
        update.driver_fee = Math.round(Number((values.amount / 100) * values.price));
      } else {
        update.driver_fee = Math.round(Number(values.amount));
      }
    }

    if (inputField === 'price') {
      if (values.driver_price_type === 'percentage') {
        update.driver_fee = Math.round(Number((values.amount / 100) * inputValue));
      } else {
        update.driver_fee = Math.round(Number(values.driver_fee));
      }
    }

    setValues({
      ...values,
      ...update
    });
  };

  const handleSenderChange = (e, sender) => {
    setValues({ ...values, sender: sender.id });
  };

  const handleDriverChange = (e, driver) => {
    setValues({ ...values, driver: driver.id });
  };

  const priceTypes = [
    {
      value: 'fixed',
      label: 'Fixed'
    },
    {
      value: 'percentage',
      label: 'Percentage'
    }
  ];

  const orderStatus = [
    {
      value: 'completed',
      label: 'Completed'
    }
  ];

  const createOrder = async () => {
    setLoading(true)
    const response = await orders.CreateOrder(values);

    if (response.success) {
      toast.success(response.message);
      setOpen(false);
      setValues(defaultOrder);
    } else {
      toast.error(response.message);
    }
    setLoading(false)
  };

  const addItems = () => {
    values.items.push({ name: '', quantity: 0 });

    setValues({
      ...values
    });
  };

  const deleteItem = (item, index) => {
    values.items.splice(index, 1);
    const newItems = values.items;

    if (values.items.length) {
      setValues({
        ...values,
        items: newItems
      });
    } else {
      setValues({
        ...values,
        items: [{ name: '', quantity: '' }]
      });
    }
  };

  return (
    <div>
      <Toaster />
      <ConfirmationModal
        open={open}
        handleClose={() => setOpen(false)}
        order={values}
        handleSuccess={createOrder}
        loading={loading}
      />
      {
        <Card {...rest} className={clsx(classes.root, className)}>
          <form autoComplete="off" noValidate>
            <CardHeader
              title="Create Order"
              // subheader="Create an order into the system"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Pickup Location"
                    margin="dense"
                    name="sender_address"
                    onChange={handleChange}
                    required
                    value={values.sender_address}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Dropoff Location"
                    margin="dense"
                    name="receiver_address"
                    onChange={handleChange}
                    required
                    value={values.receiver_address}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Receiver Name"
                    margin="dense"
                    name="receiver_name"
                    onChange={handleChange}
                    required
                    value={values.receiver_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Receiver Phone Number"
                    margin="dense"
                    name="receiver_phone"
                    onChange={handleChange}
                    required
                    value={values.receiver_phone}
                    variant="outlined"
                    placeholder="+2349035234998"
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <TextField
                    multiline
                    label="Message"
                    margin="dense"
                    name="message"
                    onChange={handleChange}
                    required
                    value={values.message}
                    variant="outlined"
                    className={clsx(classes.textare, classes.fullWidth)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateTimePicker
                    name="scheduledDateTime"
                    value={values.scheduledDateTime}
                    onChange={date => handleDateChange(date)}
                    label="Schedule date and time"
                    minDate={moment()}
                  />
                </Grid> */}

                <Grid item md={6} xs={12}>
                  <Autocomplete
                    disablePortal
                    name="sender"
                    // value={values.sender}
                    onChange={handleSenderChange}
                    fullWidth
                    options={senders}
                    renderInput={params => (
                      <TextField
                        fullWidth
                        key={params.id}
                        {...params}
                        label="Sender"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Autocomplete
                    disablePortal
                    fullWidth
                    options={drivers}
                    name="driver"
                    // value={values.driver}
                    onChange={handleDriverChange}
                    renderInput={params => (
                      <TextField
                        fullWidth
                        {...params}
                        key={params.id}
                        label="Driver"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Total Price"
                    margin="dense"
                    name="price"
                    onChange={handleChange}
                    required
                    value={values.price}
                    variant="outlined"
                    type="number"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Driver Price Type"
                    margin="dense"
                    name="driver_price_type"
                    onChange={handleChange}
                    required
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={values.driver_price_type}
                    variant="outlined">
                    {priceTypes.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Amount"
                    margin="dense"
                    name="amount"
                    onChange={handleChange}
                    required
                    value={values.amount}
                    variant="outlined"
                    type="number"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    label="Driver Price"
                    margin="dense"
                    name="driver_fee"
                    onChange={handleChange}
                    required
                    value={values.driver_fee}
                    variant="outlined"
                    type="number"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Status"
                    margin="dense"
                    name="status"
                    onChange={handleChange}
                    required
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={values.status}
                    variant="outlined">
                    {orderStatus.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <Grid item style={{ padding: '10px' }} xs={12}>
                  <Card>
                    <CardHeader title="Items" subheader="List items" />
                    {values.items.map((item, idx) => {
                      return (
                        <Grid
                          style={{
                            padding: '10px',
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                          xs={12}>
                          <Grid key={item.id} item md={6} xs={12}>
                            <TextField
                              style={{ width: '95%' }}
                              label="Name"
                              margin="dense"
                              name={`items-${idx}-name`}
                              onChange={handleChange}
                              required
                              value={item.name}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid key={item.id} md={6} xs={12}>
                            <TextField
                              style={{ width: '95%' }}
                              label="Quantity"
                              margin="dense"
                              name={`items-${idx}-quantity`}
                              onChange={handleChange}
                              required
                              value={item.quantity}
                              variant="outlined"
                              type="number"
                            />
                          </Grid>
                          <LoadingButton onClick={() => deleteItem(item, idx)}>
                            <DeleteIcon />
                          </LoadingButton>
                        </Grid>
                      );
                    })}
                    <CardActions>
                      <Button
                        onClick={addItems}
                        color="primary"
                        variant="contained">
                        Add Item
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions style={{ display: 'flex', justifyContent: 'right' }}>
              <Button onClick={() => setOpen(true)} color="primary" variant="contained">
                Create Order
              </Button>
            </CardActions>
          </form>
        </Card>
      }
    </div>
  );
};

CreateOrderDetails.propTypes = {
  className: PropTypes.string
};

export default CreateOrderDetails;
