import React, { useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

import { StatusBullet } from 'components';

const statusColors = {
  pending: 'info',
  cancelled: 'danger',
  driver_arrived: 'info',
  driver_picked_up_items: 'info',
  driver_delivered_items: 'success',
  order_completed: 'success',
  draft: 'info'
};

const statusText = {
  pending: 'Pending',
  cancelled: 'Cancelled',
  driver_arrived: 'Driver Arrived',
  driver_picked_up_items: 'Driver Picked Up Items',
  driver_delivered_items: 'Delivered',
  order_completed: 'Delivered',
  draft: 'Draft'
};

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
}));

const OrdersTable = props => {
  const { className, orders, metaData, fetchOrders, ...rest } = props;

  const classes = useStyles();

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { orders } = props;

    let selectedOrders;

    if (event.target.checked) {
      selectedOrders = orders.map(user => user.reference);
    } else {
      selectedOrders = [];
    }

    setSelectedOrders(selectedOrders);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedOrders.indexOf(id);
    let newsSelectedOrders = [];

    if (selectedIndex === -1) {
      newsSelectedOrders = newsSelectedOrders.concat(selectedOrders, id);
    } else if (selectedIndex === 0) {
      newsSelectedOrders = newsSelectedOrders.concat(selectedOrders.slice(1));
    } else if (selectedIndex === selectedOrders.length - 1) {
      newsSelectedOrders = newsSelectedOrders.concat(
        selectedOrders.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newsSelectedOrders = newsSelectedOrders.concat(
        selectedOrders.slice(0, selectedIndex),
        selectedOrders.slice(selectedIndex + 1)
      );
    }

    setSelectedOrders(newsSelectedOrders);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchOrders({ page: page + 1, perPage: rowsPerPage });
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
    fetchOrders({ page: page + 1, perPage: event.target.value });
  };

  const navigateToOrderDetails = id => {
    props.history.push(`/orders/${id}`);
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedOrders.length === orders.length}
                      color="primary"
                      indeterminate={
                        selectedOrders.length > 0 &&
                        selectedOrders.length < orders.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Reference</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Driver</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Sender Address</TableCell>
                  <TableCell>Delivery Address</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Driver Fee</TableCell>
                  <TableCell>Revenue</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map(order => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={order.reference}
                    selected={selectedOrders.indexOf(order.reference) !== -1}
                    onClick={() => navigateToOrderDetails(order.reference)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedOrders.indexOf(order.reference) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, order.reference)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          {order.created_at}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>{order.reference}</TableCell>
                    <TableCell>{order.sender_data.name}</TableCell>
                    <TableCell>{order.driver_data ? order.driver_data.name : 'No driver'}</TableCell>
                    <TableCell>
                      <div className={classes.statusContainer}>
                        <StatusBullet
                          className={classes.status}
                          color={statusColors[order.status]}
                          size="sm"
                        />
                        {statusText[order.status]}
                      </div>
                    </TableCell>
                    <TableCell>{order.sender_address}</TableCell>
                    <TableCell>{order.receiver_address}</TableCell>
                    <TableCell>{order.price}</TableCell>
                    <TableCell>{order.driver_fee}</TableCell>
                    <TableCell>
                      {Number(order.price - order.driver_fee).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={metaData.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

OrdersTable.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array.isRequired
};

export default withRouter(OrdersTable);
